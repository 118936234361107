/*
Script by : Clem
-----------------------------------------
||           Bootstrap nav++           ||
-----------------------------------------

xxxxxxxx
Rien à modifier dans ce fichier.
*/

// Ouverture des dropdowns si option true
if(navbar_dropdown_on_over == true) {
	// DROPDOWN NAV SUR HOVER
	$('.nav-item.dropdown').mouseenter(
		function() {
			if (typeof mouse_left !== 'undefined') { clearTimeout(mouse_left); }
			if(!$(this).hasClass('show')) {
				$(this).find('[data-toggle=dropdown]').dropdown('toggle');
			}
		}
	);
	$('.nav-item.dropdown').mouseleave(
		function() {
			if($(this).hasClass('show')) {
				mouse_left = setTimeout(function(){
				$(this).find('[data-toggle=dropdown]').dropdown('toggle');
				$('body').click();
				},300);
			}
		}
	);
}

// Animer la navbar-collapse si on est en horizontal
if(collapse_opening_direction == 'horizontal') {
	$('.navbar-toggler').on('click', function() {

		if($('.navbar-collapse').hasClass('show')) {
			translate_state = 100;
			var animate_collapse = window.setInterval(function(){
				$('.navbar-collapse').css('transform', 'translateX('+translate_state+'%)');
				translate_state - 2;
				if (translate_state <= 0) {
					clearInterval(animate_collapse);
				}
			}, 10);
			$('.navbar-collapse').attr('style', '');
		} else {
			translate_state = 0;
			var animate_collapse = window.setInterval(function(){
				$('.navbar-collapse').css('transform', 'translateX('+translate_state+'%)');
				translate_state + 2;
				if (translate_state >= 100) {
					clearInterval(animate_collapse);
				}
			}, 10);
			$('.navbar-collapse').attr('style', '');
		}

	});
}
