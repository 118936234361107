
function PhpSessionWrite(name,value) {

	$.ajax({
	type: 'GET',
	url: '/admin/php/ajax_php_session_write.php?'+name+'='+value,
	success: function(data) {
		if(debug_mode == true) { console.log('Variable de session définie : '+data); } },
	error: function() {
		console.log('Echec lors de la création de la variable de session : '+data); }
	});
};

function SelectText(element) {
    var text = document.getElementById(element);
    if ($.browser.msie) {
        var range = document.body.createTextRange();
        range.moveToElementText(text);
        range.select();
    } else if ($.browser.mozilla || $.browser.opera) {
        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(text);
        selection.removeAllRanges();
        selection.addRange(range);
    } else if ($.browser.safari) {
        var selection = window.getSelection();
        selection.setBaseAndExtent(text, 0, text, 1);
    }
}

function reloadScroll() {
    var scrollAmount = document.body.scrollTop; // this is the current scroll position

    document.cookie="scrollAmount=" + scrollAmount; // save it in cookie
    window.dontkillcookie = true; // just a flag used in onunload
    window.location.reload();
    /*window.location.href = window.location.pathname;*/
}

window.onload = function () // when the window is reloaded, check if a scroll value has been saved
{
    var scrollAmount;

    var cook = document.cookie; // parse the cookie
    var pos = cook.indexOf("scrollAmount=");
    if (pos >= 0) {
        scrollAmount = parseInt(cook.substr(pos + 13));
    }

    if (scrollAmount) // and reset the scrolling. et voilà.
    {
    document.body.scrollTop = scrollAmount;
    }
}

window.onunload = function() {
// reset the cookie to zero, this way the window won't
// scroll the next time the user accesses it
    if (!window.dontkillcookie)
    {
    document.cookie="scrollAmount=0";
    }
}