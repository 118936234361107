
// // ANIMATION titre début
// gsap.to('.animated_zone .texte_debut', { 
//     opacity: 0,
//     scrollTrigger: {
//         trigger: '.screenblock._2',
//         end: 'top+=200 bottom',
//         scrub: true,
//         //markers: true
//     }
// });
// gsap.to('.animated_zone .circle.outerblack', { 
//     opacity: 0,
//     scrollTrigger: {
//         trigger: '.screenblock._2',
//         end: 'top+=200 bottom',
//         scrub: true,
//         //markers: true
//     }
// });

// // ANIMATION titre début
// gsap.to('.animated_zone .texte', { 
//     opacity: 0.5,
//     scrollTrigger: {
//         trigger: '.screenblock._2',
//         start: 'top+=200 bottom',
//         end: 'top+=400 bottom',
//         scrub: true,
//     }
// });
// gsap.to('.animated_zone .texte p', { 
//     opacity: 0,
//     scrollTrigger: {
//         trigger: '.screenblock._2',
//         start: 'bottom+=0 bottom',
//         end: 'bottom+=200 bottom',
//         scrub: true,
//     }
// });
// gsap.to('.animated_zone .texte p:nth-child(1)', { x: '0px',
//     scrollTrigger: {
//         trigger: '.screenblock._2',
//         scrub: true
//     }
// });
// gsap.to('.animated_zone .texte p:nth-child(2)', { x: '0px',
//     scrollTrigger: {
//         trigger: '.screenblock._2',
//         scrub: true
//     }
// });
// gsap.to('.animated_zone .texte p:nth-child(3)', { x: '0px',
//     scrollTrigger: {
//         trigger: '.screenblock._2',
//         scrub: true
//     }
// });
// gsap.to('.animated_zone .texte p:nth-child(4)', { x: '-50px',
//     scrollTrigger: {
//         trigger: '.screenblock._2',
//         scrub: true
//     }
// });
// gsap.to('.animated_zone .texte p:nth-child(5)', { x: '70px',
//     scrollTrigger: {
//         trigger: '.screenblock._2',
//         scrub: true
//     }
// });

// // ANIMATION CERCLES
// // ANIMATION container
// gsap.to('.animated_zone .circles_container', { 
//     rotation: 300,
//     height: '70vw',
//     width: '70vw',
//     scrollTrigger: {
//         trigger: '.screenblock._2_3',
//         //start: 'top',
//         scrub: true,
//         //markers: true
//     }
// });

// // ANIMATION position cercles -> centre
// gsap.to('.animated_zone .circles_container .circle', { 
//     top: '50%',
//     left: '50%',
//     x: '-50%',
//     y: '-50%',
//     scrollTrigger: {
//         trigger: '.screenblock._25',
//         start: 'center bottom',
//         scrub: true
//     }
// });

// // ANIMATION couleurs cercles
// gsap.to('.animated_zone .circles_container .circle.yellow', { 
//     backgroundColor: 'rgba(168, 143, 38, 1)',
//     scrollTrigger: {
//         trigger: '.screenblock._25',
//         //start: 'top',
//         scrub: true
//     }
// });
// gsap.to('.animated_zone .circles_container .circle.black', { 
//     backgroundColor: 'rgba(0, 0, 0, 1)',
//     scrollTrigger: {
//         trigger: '.screenblock._25',
//         //start: 'top',
//         scrub: true
//     }
// });
// gsap.to('.animated_zone .circles_container .circle.white', { 
//     backgroundColor: 'rgba(255, 255, 255, 1)',
//     scrollTrigger: {
//         trigger: '.screenblock._25',
//         //start: 'top',
//         scrub: true
//     }
// });

// // ANIMATION tailles cercles
// gsap.to('.animated_zone .circles_container .circle.yellow', { 
//     height: '150px',
//     width: '150px',
//     scrollTrigger: {
//         trigger: '.screenblock._3',
//         start: 'center bottom',
//         scrub: true
//     }
// });
// // gsap.to('.animated_zone .circles_container .circle.outerblack', {
// //     height: '30px',
// //     width: '30px',
// //     opacity: 1,
// //     scrollTrigger: {
// //         trigger: '.screenblock._3',
// //         start: 'center bottom',
// //         scrub: true
// //     }
// // });
// gsap.to('.animated_zone .circles_container .circle.black', {
//     height: '0px',
//     width: '0px',
//     scrollTrigger: {
//         trigger: '.screenblock._3',
//         start: 'center bottom',
//         scrub: true
//     }
// });
// gsap.to('.animated_zone .circles_container .circle.white', { 
//     scrollTrigger: {
//         trigger: '.screenblock._3',
//         start: 'center bottom',
//         scrub: true
//     }
// });

// // ANIMATION tailles cercles 2e partie
// gsap.to('.animated_zone .circles_container .circle.yellow', { 
//     height: '0px',
//     width: '0px',
//     scrollTrigger: {
//         trigger: '.screenblock._4',
//         end: 'bottom-=200 top',
//         scrub: true
//     }
// });
// gsap.to('.animated_zone .circles_container .circle.outerblack', {
//     height: '100px',
//     width: '100px',
//     opacity: 0,
//     scrollTrigger: {
//         trigger: '.screenblock._4',
//         //start: 'center bottom',
//         scrub: true
//     }
// });
// gsap.to('.animated_zone .circles_container .circle.white', { 
//     height: '120vh',
//     width: '120vh',
//     scrollTrigger: {
//         trigger: '.screenblock._4',
//         //start: 'center bottom',
//         scrub: true
//     }
// });

// gsap.to('.animated_zone', { 
//     clipPath: 'inset(0 0 100vh 0)',
//     ease: 'linear',
//     scrollTrigger: {
//         trigger: '.screenblock._5',
//         end: 'bottom-=100 bottom',
//         scrub: true
//     }
// });


// gsap.to('.animated_zone img', { 
//     clipPath: 'circle(0% at 50% 50%)',
//     scrollTrigger: {
//         trigger: '.screenblock._3',
//         //start: 'top',
//         scrub: true
//     }
// });