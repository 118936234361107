/*
-----------------------------------------------------------
||                   SVG anim on hover                   ||
-----------------------------------------------------------
*/

$(document).ready(function() {

    var path;
    
    $('.lien_trait_ondule').hover(function () {
            path_element = $(this).find('.path');
            path = Snap(path_element[0]);
            path.animate({ d: "M 5,5 c 20,0 30,20 50,20 c 15,0 30,-20 50,-20 c 20,0 30,20 50,20 c 15,0 30,-20 50,-20c 20,0 30,20 50,20 c 15,0 30,-20 40,-20" }, 60, mina.easeout);
            
        }, function () {
            path.animate({ d: "M 5,15 c 20,0 30,0 50,0 c 15,0 30,0 50,0 c 20,0 30,0 50,0 c 15,0 30,0 50,0c 20,0 30,0 50,0 c 15,0 30,0 40,0" }, 60, mina.easeout);
        }
    );

});
