/*
Script by : Clem
-------------------------------------------
||          Goto carousel slide          ||
-------------------------------------------

Utiliser les attributs goto-carousel-slide="nb" et target-carousel="#carousel" pour faire bouger le carousel choisi au slide choisi.
*/

$(document).ready(function() {
	if($('[goto-carousel-slide]').length) {

		$('[goto-carousel-slide]').on('click', function(e) {

			var target_carousel = $(this).attr('target-carousel');
			var goto_slide = parseInt($(this).attr('goto-carousel-slide'));

			$(target_carousel).carousel(goto_slide);

		});

	}
});