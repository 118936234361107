
if($('.test_smooth').length) {

    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, DrawSVGPlugin);

    // create the scrollSmoother before your scrollTriggers
    ScrollSmoother.create({
        smooth: 1.5,               // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true,           // looks for data-speed and data-lag attributes on elements
        smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });

    anim_fleurs = gsap.timeline({repeat:0, yoyo:false});

    anim_fleurs.fromTo('path', {drawSVG:"0%"}, {duration: 0.4, drawSVG:"0% 100%"})
                .fromTo('circle', {scaleX:0, scaleY:0}, {duration: 0.2, scaleX:1, scaleY:1})
                .fromTo('line', {drawSVG:"0%"}, {duration: 0.3, drawSVG:"0% 100%"});

    anim_fleurs.pause();

    $('button').mouseenter(function () { 
        anim_fleurs.play();
    });
    $('button').mouseleave(function () { 
        anim_fleurs.reverse();
    });

}