
$(document).ready(function() {
    // var rellax = new Rellax('[data-rellax-speed]', {
    //     // speed: -2,
    //     center: true,
    //     // wrapper: null,
    //     // round: true,
    //     // vertical: true,
    //     // horizontal: false
    // });
});