
$(document).ready(function() {

	deleting = 0;
	$('.delete_entry').on('click', function(e) {
		deleting = 1;
		setTimeout(function(){
			deleting = 0;
		}, 100);
		
		// Récupération des infos de suppression
		delete_table = $(this).attr('delete-table');
		delete_column = $(this).attr('delete-column');
		delete_id = $(this).attr('delete-id');
		current_form = $(this).attr('current-form');
		delete_file = $(this).attr('delete-file');

		// Mise à jour du bouton de suppression de la modale de confirmation
		$('#modal_supprimer .btn_suppr')
		.attr('delete-table', delete_table)
		.attr('delete-column', delete_column)
		.attr('delete-id', delete_id)
		.attr('current-form', current_form)
		.attr('delete-file', delete_file);

		// Garder en mémoire la ligne à suppr du tableau
		form_line = $(this).closest('.table-row');

		// Afficher la modale de confirmation
		$('#modal_supprimer').modal('toggle');

		$('#modal_supprimer .btn_suppr').on('click', function() {
			delete_table = $(this).attr('delete-table');
			delete_column = $(this).attr('delete-column');
			delete_id = $(this).attr('delete-id');
			current_form = $(this).attr('current-form');
			delete_file = $(this).attr('delete-file');

			if(current_form == 'users') { php_file = 'ajax_delete_user'; }
			else { php_file = 'ajax_delete_entry'; }

			// Appeler le script PHP de suppression
			$.ajax({
				type: 'GET',
				url: '/admin/php/'+php_file+'.php?delete_table='+delete_table+'&delete_column='+delete_column+'&delete_id='+delete_id+'&delete_file='+delete_file,
				success: function(data) {
					form_line.fadeOut(500);
					setTimeout(function(){
					    form_line.remove();
					}, 500);
					$('#modal_supprimer').modal('toggle');
					if(debug_mode == true) { 
						console.log('L\'entrée suivante a été supprimée : delete_table = '+delete_table+', delete_column = '+delete_column+', delete_id = '+delete_id+', delete_file = '+delete_file); 
					} 
				},
				error: function() {
					if(debug_mode == true) { 
						console.log('Echec lors de la suppression de l\'entrée : delete_table = '+delete_table+', delete_column = '+delete_column+', delete_id = '+delete_id+', delete_file = '+delete_file); 
					} 
				}
			});
		});
	});
});