/*
Script by : Clem
---------------------------------------
||              JS Menu              ||
---------------------------------------

SUR LES BOUTONS : mettre la classe "js-menu-button", mettre en href un anchor de son choix, attribut js-menu-group="nom_du_groupe".
SUR LES CONTENT : mettre la classe "js-menu-content", mettre une classe content_hrefprecedent, attribut js-menu-group="nom_du_groupe".
CSS : il faut qu'une classe "hidden" soit déclarée avec un display:none.
CSS : les "a" des boutons prennent la classe "active" quand cliqués, utiliser a.active pour styliser le bouton actuellement actif.
Rien à modifier dans ce fichier.
*/


$(document).ready(function() {
	
	// GESTION DU CLIC SUR UN BOUTON DE LA NAV JS
	$('.js-menu-button').on('click', function(e) { // Au clic sur un élément
		/*e.preventDefault();*/

		var clicked_group = $(this).attr('js-menu-group');
		var button_target = $(this).attr('href');

		// Enlever tous les "active" du même groupe
		$('.js-menu-button').each(function() {
			var button_group = $(this).attr('js-menu-group');
			if(button_group == clicked_group) {
				$(this).removeClass('active');
			}
		});

		// Ajouter la class "active" sur le bouton cliqué
		$(this).addClass('active');

		// Changer le hash dans l'URL
		window.location.hash = button_target;

		// Cacher tous les content du même groupe
		$('.js-menu-content').each(function() {
			var content_group = $(this).attr('js-menu-group');
			if(content_group == clicked_group) {
				$(this).addClass('hidden');
			}
		});

		// Afficher le content du bouton cliqué
		$('.js-menu-content.content_'+button_target.substr(1)).removeClass('hidden');

	});

	// GESTION DU CHARGEMENT DU BON CONTENT AU CHARGEMENT SI IL Y A ANCHOR
	if(window.location.hash) {
        var anchor_called = window.location.hash;

        $('.js-menu-content').each(function() {
			$(this).addClass('hidden');
		});

		$('.js-menu-content.content_'+anchor_called.substr(1)).removeClass('hidden');

		$('.js-menu-button').each(function() {
			$(this).removeClass('active');
		});

		$('a[href="'+anchor_called+'"]').addClass('active');
    }

});