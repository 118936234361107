
$('.select_all_on_click').on('click', function (e) { 
    e.preventDefault();
    this.select();
    document.execCommand('copy');

    $('.copied').addClass('visible');
    setTimeout(() => {
        $('.copied').removeClass('visible');
    }, 1000);
});