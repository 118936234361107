/*
Script by : Clem
------------------------------------------------------------------------
||        FixSwitch : switch from absolute to fixed in element        ||
------------------------------------------------------------------------

Mettre une class "fixswitch_container" sur le container
Mettre une class "fixswitch_element" sur l'élément contenu dedans
*/

$(document).ready(function() {

    if($('.fixswitch_container').length) {

        element_state = 'absolute_top';
        checkFixswitch($('.fixswitch_container'));

        $(window).scroll(function (event) {
            checkFixswitch($('.fixswitch_container'));
        });

        function checkFixswitch(container) {
            var window_top_position = $(window).scrollTop();
            var window_height = $(window).height();
            var window_bottom_position = window_top_position + window_height;
            var container_top_position = container.offset().top;
            var container_height = container.outerHeight();
            var container_bottom_position = container_top_position + container_height;

            // If container top reached window top, fix element
            if(window_top_position < container_top_position) {
                if(element_state != 'absolute_top') {
                    element_state = 'absolute_top';
                    container.removeClass('absolute_bottom');
                    container.removeClass('fixed');
                    container.addClass('absolute_top');
                }
            } else if(window_top_position > container_top_position && window_bottom_position < container_bottom_position) {
                if(element_state != 'fixed') {
                    element_state = 'fixed';
                    container.removeClass('absolute_top');
                    container.removeClass('absolute_bottom');
                    container.addClass('fixed');
                }
            } else {
                if(element_state != 'absolute_bottom') {
                    element_state = 'absolute_bottom';
                    container.removeClass('absolute_top');
                    container.removeClass('fixed');
                    container.addClass('absolute_bottom');
                }
            }
        }

    }

});
