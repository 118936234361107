/*
Script by : Clem
---------------------------------------
||             Scrollnav             ||
---------------------------------------

À coupler avec js_scrollto !

Juste utiliser l'HTML suivant : 
<div class="scrollnav">
	<a href="#section1" class="js-scrollTo"><div class="scrollnav_link"></div></a>
	<a href="#section2" class="js-scrollTo"><div class="scrollnav_link"></div></a>
	<a href="#section3" class="js-scrollTo"><div class="scrollnav_link"></div></a>
	<a href="#section4" class="js-scrollTo"><div class="scrollnav_link"></div></a>
	<a href="#section5" class="js-scrollTo"><div class="scrollnav_link"></div></a>
</div>
en mettant en href les différents IDs des "étages" de la nav
Rien à modifier dans ce fichier.
*/


$(document).ready(function() {
	if($('.scrollnav').length) {

		$('.scrollnav_link').each(function(i, obj) {

			var current_div = $(this);
			var link = $(this).parent().attr('href');
			var tolerance = 80;

			$(window).scroll(function (event) {

				target_top_distance_from_top = $(link).offset().top;
				target_height = $(link).height();
				target_bottom_distance_from_top = target_top_distance_from_top + target_height;
				window_distance_from_top = window.pageYOffset;
				// Tolérance
				target_top_distance_from_top = target_top_distance_from_top - tolerance;
				target_bottom_distance_from_top = target_bottom_distance_from_top + tolerance;
				
				if(target_top_distance_from_top <= window_distance_from_top-1 && window_distance_from_top <= target_bottom_distance_from_top) {
					current_div.addClass('active');
				} else {
					current_div.removeClass('active');
				}

			});

		});

	}
});