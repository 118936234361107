
if($('.test_ugp_svg').length) {
    gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, MotionPathPlugin, ScrollSmoother);

    // create the scrollSmoother before your scrollTriggers
    ScrollSmoother.create({
        smooth: 1.5,               // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true,           // looks for data-speed and data-lag attributes on elements
        smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });

    var shapes = "path";
    var tl = gsap.timeline({repeat:0, yoyo:false});

    tl.fromTo('.slow', {drawSVG:"0% 0%"}, { 
        drawSVG:"0% 100%",
        scrollTrigger: {
            trigger: '.screenblock._3',
            //end: 'top+=200 bottom',
            scrub: true,
            //markers: true
        }
    });

    tl.fromTo('.fast', {drawSVG:"0% 0%"}, { 
        drawSVG:"0% 100%",
        scrollTrigger: {
            trigger: '.screenblock._2',
            start: 'center bottom',
            end: 'center+=300 bottom',
            scrub: true
        }
    });

    tl.play();

    gsap.to(".boule", {
        motionPath:{
            path: ".mainpath",
            align: ".mainpath",
            autoRotate: true,
            alignOrigin: [0.5, 0.5]
        },
            scrollTrigger: {
            trigger: '.screenblock._3',
            start: 'top+=200 bottom',
            end: 'bottom+=200 top',
            scrub: true,
            //markers: true
        }
    });
}