/*
Script by : Clem
-------------------------------------------
||           Swipe on Carousel           ||
-------------------------------------------
*/

// ----- OPTIONS -----
swipe_tolerance = 10;
swipe_weight = 0.3;
clamp = 100;
// -------------------

holding_mouse = 0;
jQuery(document).ready(function() {
	if(jQuery('.carousel').length) {
		
		jQuery('.carousel').on('mousedown', function (e) {
			holding_mouse = 1;
			mouse_delta = 0;
			mouse_pos_start_X = e.pageX;
			console.log(mouse_pos_start_X);
		});

		jQuery('.carousel').on('mouseup', function (e) {
			holding_mouse = 0;
			console.log(swipe_tolerance);
			jQuery('.carousel-item:not(.active)').css('left', '');
			if (mouse_delta > swipe_tolerance) { jQuery('.carousel').carousel('prev'); }
			if (mouse_delta < -swipe_tolerance) { jQuery('.carousel').carousel('next'); }
		});

		jQuery('.carousel').mousemove(function (e) {
			if (holding_mouse) {
				mouse_delta = e.pageX - mouse_pos_start_X;
				console.log('Mouse delta : ' + mouse_delta);
				item_delta = mouse_delta * swipe_weight;
				item_delta = item_delta <= -clamp ? -clamp : item_delta >= clamp ? clamp : item_delta;
				jQuery(this).find('.carousel-item.active').css('left', '' + item_delta + 'px');
			}
		});

	}
});
