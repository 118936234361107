/*
Script by : Clem
--------------------------------------------
||           Google maps loader           ||
--------------------------------------------

Pour charger une carte googlemap dans une div, lui donner la classe "carte_googlemaps", et des attributes coord="x.xxxx,x.xxxx" et zoom="xx"
*/

$(document).ready(function() {
    if($('.carte_googlemaps').length) {

        $('.carte_googlemaps').each(function() {

            $this = $(this);

            function initialisation(){
                gmap_coord = $this.attr('coord');
                gmap_coord = gmap_coord.split(',');
                gmap_zoom = parseInt($this.attr('zoom'));
                if(debug_mode) { console.log('Loading gmap on "'+$this.attr('class')+'" with coordinates '+gmap_coord+' and zoom '+gmap_zoom); }

                var optionsCarte = {
                    zoom: gmap_zoom,
                    center: new google.maps.LatLng(parseFloat(gmap_coord[0]), parseFloat(gmap_coord[1])),
                    'scrollwheel': false,
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP
                    },
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false
                }

                var maCarte = new google.maps.Map($(this), optionsCarte);

                var optionsMarqueur = {
                    position: maCarte.getCenter(),
                    map: maCarte
                };
                var marqueur = new google.maps.Marker(optionsMarqueur);
            }
            google.maps.event.addDomListener(window, 'load', initialisation);

        });

    }
    
});