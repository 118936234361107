/*
Script by : Clem
---------------------------------------
||         Animation trigger         ||
---------------------------------------

Utiliser l'attribut "detect-in-view" sur une div pour lui ajouter la class "in-view" lorsqu'elle est dans l'écran.
La valeur de l'attribut permet de choisir quand la classe in-view sera ajoutée :
- "begin" : la classe sera ajoutée dès qu'un pixel de la div sera entré dans l'écran
- "full" : la classe sera ajoutée dès que la div sera entierement affichée dans l'écran
- "100" : la classe sera ajoutée lorsque 100px de la div seront rentrés dans l'écran
- "-50" : la classe sera ajoutée 50px avant que la div rentre dans l'écran
Il est possible d'ajouter l'attribut "noreset" pour que l'animation ne se déclenche qu'une seule fois même si la div sort et rerentre dans l'écran.

Mettre la classe "detect-in-view" aux divs qui doivent être détectées.
Elles se verront ajouter la classe "in-view" lorsqu'elles seront dans l'écran.
Il est possible d'ajouter la classe "noreset" pour que l'animation ne se déclenche qu'une seule fois même si la div sort et rerentre dans l'écran.
L'attribut "detect-on" permet de choisir quand la classe in-view sera ajoutée :
- "begin" : la classe sera ajoutée dès qu'un pixel de la div sera entré dans l'écran
- "full" : la classe sera ajoutée dès que la div sera entierement affichée dans l'écran
- "100" : la classe sera ajoutée lorsque 100px de la div seront rentrés dans l'écran
- "-50" : la classe sera ajoutée 50px avant que la div rentre dans l'écran

Rien à modifier dans ce fichier.
*/

$(document).ready(function() {

	if($('[detect-in-view]').length) {

		var animation_elements = $('[detect-in-view]'); // Mettre cette class aux éléments qu'on veut animer à chaque passage dans le viewport
		//var animation_elements_noreset = $('.detect-in-view-noreset'); // Mettre cette class aux éléments qu'on ne veut animer qu'une seule fois puis garder en position finale
		var $window = $(window);
		var window_height = $window.height();

		function check_if_in_view() {
			var window_top_position = $window.scrollTop();
			var window_bottom_position = (window_top_position + window_height);

			$.each(animation_elements, function() {
				var element = $(this);
				var element_height = element.outerHeight();
				var element_top_position = element.offset().top;
				var element_bottom_position = (element_top_position + element_height);

				// Apply the detect-on modifier
				var detect_on = element.attr('detect-in-view');
				if((detect_on == 'begin') || (detect_on == null)) {
					trigger_top = element_bottom_position;
					trigger_bottom = element_top_position;
				} else if(detect_on == 'full') {
					trigger_top = element_top_position;
					trigger_bottom = element_bottom_position;
				} else {
					detect_on = parseInt(detect_on);
					trigger_top = element_bottom_position - detect_on;
					trigger_bottom = element_top_position + detect_on;
				}

				// Check if the element is considered in view or not
				if ((trigger_top >= window_top_position) && (trigger_bottom <= window_bottom_position)) {
					if(!element.hasClass('in-view')) {
						element.addClass('in-view');
						if(debug_mode) { console.log('Adding "in-view" class to : '+$(this).attr('class')); }
					}
				} else {
					// On enlève le "in-view" seulement si l'élément n'est pas en noreset
					if(!element[0].hasAttribute('noreset')) {
						if(element.hasClass('in-view')) {
							element.removeClass('in-view');
							if(debug_mode) { console.log('Removing "in-view" class to : '+$(this).attr('class')); }
						}
					}
				}
			});
		}

		check_if_in_view();

		$window.on('scroll resize', check_if_in_view);
		$window.trigger('scroll');
}
});