
$(document).ready(function() {

	$('.check_modified_inputs .form-control').on('click', function() {

		$(this).addClass('input_modified');
		$(this).parent().parent().find('.line_modified').val('1');

	});

	$('.check_modified_inputs .file_td').on('click', function() {

		$(this).addClass('input_modified');
		$(this).parent().find('.line_modified').val('1');

	});


});