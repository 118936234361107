/*
Script by : Clem
------------------------------------------------------------------------
||        FixSwitch : switch from absolute to fixed in element        ||
------------------------------------------------------------------------

Mettre une class "fixswitch_container" sur le container
Mettre une class "fixswitch_element" sur l'élément contenu dedans
*/

$(document).ready(function() {

  $(window).on('load', function() {
      // $(this).impulse({
      //   range: 30,
      //   leap: 1.8
      // });
    });
});


