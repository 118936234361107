/*
Script by : Clem
--------------------------------------------
||        AddClass on scroll level        ||
--------------------------------------------

Mettre un attribut detect-scroll-level="" sur une div avec le nombre de pixels de scroll à partir desquels on veut ajouter une class.
Dès que le niveau de scroll sera atteint, la div se verra ajouter la classe "scroll-level-reached"
*/

$(document).ready(function() {
	if($('[detect-scroll-level]').length) {

		$('[detect-scroll-level]').each(function(i, obj) {
			var current_div = $(this);
		    var required_scroll = $(this).attr('detect-scroll-level');
		    var current_div_classes = $(this).attr('class');

			$(window).scroll(function (event) {

			    var scroll = $(window).scrollTop();

			    if(scroll > required_scroll) {
			    	if(!current_div.hasClass('scroll-level-reached')) {
				    	current_div.addClass('scroll-level-reached');
				    	/*if(current_div.hasClass('navbar_container')) {
				    		$('.navbar').removeClass('navbar-expand-lg');
				    	}*/
				    	if(debug_mode) { console.log('Scroll ('+required_scroll+') TRUE for : '+current_div_classes); }
				    }
			    }

			    if(scroll < required_scroll) {
			    	if(current_div.hasClass('scroll-level-reached')) {
				    	current_div.removeClass('scroll-level-reached');
				    	/*if(current_div.hasClass('navbar_container')) {
				    		$('.navbar').addClass('navbar-expand-lg');
				    	}*/
				    	if(debug_mode) { console.log('Scroll ('+required_scroll+') FALSE for : '+current_div_classes); }
				    }
			    }
			    

			});
		});

	}
});
