/*
---------------------------------------------------------------
||             Word splitting with splitting.js             ||
---------------------------------------------------------------
*/

$(document).ready(function() {

    Splitting();

});
